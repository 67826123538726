body {
  background-color: #fff;
  color: #333;
  margin: 33px;
  font-family: verdana, arial, helvetica, sans-serif;
  font-size: 18px;
  line-height: 20px;
  overflow-x: hidden;
}

p, ol, ul, td {
  font-family: verdana, arial, helvetica, sans-serif;
  font-size: 18px;
  line-height: 20px;
}

pre {
  background-color: #eee;
  padding: 10px;
  font-size: 11px;
}

hr {
  border-style: ridge;
}

a {
  color: #000;
  text-decoration: none;
  &:visited {
    color: #666;
  }

  &:hover {
    color: #fff;
    background-color: #000;
  }

  &:not(.waves-effect) {
    text-decoration: underline;
  }
}

.search-title {
  font-weight: 400;
  font-size: $h3-fontsize;
}


b, strong{
  font-weight: 800;
}

th {
  padding-bottom: 8px;
  padding-top: 0;
}

td {
  padding: 0 5px 7px;
}

th.ellipsis, td.ellipsis {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td.minwidth {
  min-width: 100px;
}

th.maxwidth, td.maxwidth {
  max-width: 75px;
}


@media #{$medium-and-down} {
  th.ellipsis, td.ellipsis {
    max-width: initial;
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
  }
}

div {
  &.field, &.actions {
    margin: 1.5em 0;
    &.btn{
      width: 100%;
    }
  }
}

.actions > .btn, input[type=submit], label {
  width: 100%;
}

textarea.materialize-textarea.validate + label:not(.label-icon).active {
  transform: translateY(-20px);
}



#notice {
  color: green;
}

#error_explanation {
  border: 2px solid #c00;
  border-radius: 2px;
  padding: 7px 7px 0;
  margin-bottom: 20px;
  background-color: #f0f0f0;

  h2 {
    text-align: left;
    font-weight: bold;
    padding: 5px 5px 5px 15px;
    font-size: 12px;
    margin: -7px -7px 0;
    background-color: #c00;
    color: #fff;
  }

  ul{
    padding-left: 21px;
  }

  ul li {
    font-size: 12px;
    list-style: square;
  }
}

label {
  display: block;
}

.input-field{
  margin-top: 0;
}

.input-field em{
  position: relative;
  top: -1em;
}

.search.input-field {
  margin-top: 2em;
}

.hidden {
  display: none !important;
}

/* pagination */
.pagination {
  text-align: center;
  padding: 1em;
  cursor: default;
}
.pagination a, .pagination span {
  padding: 0.2em 0.3em;
}
.pagination .disabled {
  color: #aaaaaa;
}
.pagination .current {
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  line-height: 1.5em;
}

.materialbox-caption{
  line-height: 75px;
}

.collection{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.clear-collection{
  clear:both;
}

.entry{
  margin-bottom: 5em;
}

.home-nav{
  height: 4.28rem;
  padding-top: 1rem;
}

h3.sticky-nav{
  overflow: hidden;
}

h2.sticky-nav{
  min-height: 4rem;
}

li.sticky-nav{
  min-height: 3rem;
}

.intro {
  word-break: break-word;
}
.intro .collection{
  border: none;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.intro .collection-header{
  padding:10px 20px 0 20px;
  margin-bottom: 10px;
}
.intro .collection-item{
  border: none;
  padding: 0 0 0.5rem 0;
}
.intro-page-container{
  min-height: 100vh;
  margin-bottom: 20vh;
}

.dropcap{
  float: left;
  font-size: 75px;
  line-height: 55px;
  padding-right: 5px;
}

.project-banner-link {
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 7;
  text-decoration: none !important;
}
.project-banner-link:hover {
  color: #333;
  background-color: transparent;
}
.project-banner-link .project-banner-elem div{
  -webkit-transition: opacity 200ms ease-in;
    -moz-transition: opacity 200ms ease-in;
    -o-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in;
}
.project-banner-elem div {
  opacity: 0.75;
}
.project-banner-link:hover .project-banner-elem div {
  opacity: 1;
}
.project-banner-wrapper {
  display: flex;
  justify-content: space-between;
}
.project-banner-blob {
  height: 100%;
  width: 100%;
  text-align: right;
  margin-right: 0.5em;
  flex: 5;
}
.project-banner-separator {
  position: relative;
  left: -33px;
  width: 100vw;
  top: 0;
  z-index:-10;
  animation: 2s fadeInLinez;
}
@keyframes fadeInLinez {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.project-banner-elem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  max-width: 420px;
  height: 302px;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 250ms ease-in;
    -moz-transition: opacity 250ms ease-in;
    -o-transition: opacity 250ms ease-in;
    transition: opacity 250ms ease-in;
}
.project-banner-elem-anim {
  opacity: 1;
}
.project-banner-elem-anim div {
  animation: 333ms slidein;
}
@keyframes slidein {
  from { transform: scaleX(0); }
  to   { transform: scaleX(1); }
}

@media #{$small-and-down} {
  .project-banner-wrapper {
    flex-direction: column;
  }
  .project-banner-separator {
    display: none;
  }
  .project-banner-elem {
    background-position: center;
    background-size: cover;
    max-width: 100vw;
  }
  .project-banner-link {
    flex: 1;
  }
  .project-banner-blob {
    flex: 1;
    text-align: left;
  }
}
.project-banner-elem div {
  text-align: right;
  margin-top: 2px;
  padding: 0 6px;
  color: white;
  background-color: black;
  background-color: rgba(0,0,0,0.6);
}

.spacer-blob {
  display: flex;
  justify-content: space-between;
  background: url('/assets/img/emergency_navblob.svg');
  height: 24px;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  animation: 4s fadeInBlob;
}
@keyframes fadeInBlob {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.index-about {
  margin-top: 2em;
}

.nysca {
  margin-top: 6em;
  font-size: 16px;
}

.nysca img {
  width: 100%;
}

@media #{$small-and-down} {
  body{
    margin: 0;
    padding: 0 0.5rem;
  }

  .collapsible.popout > li{
    margin: 0;
  }

  h2 {
    font-size: 2.5rem;
  }
  h1{
    margin: 0;
    /*margin: 0 1rem 1rem 1rem;*/
  }
  .intro{
    margin-top: 66px;
  }
submit
  .intro-page-container h1{
    font-size: 13vw;
  }
  .intro-page-container h3{
    font-size: 10vw;
  }

}

@media (min-width: 1534px) {
  body, p, ol, ul, td, pre {
    font-size: 22px;
    line-height: 25px;
  }
}

.faq {
  dt, dd {
  padding: 0.75em;
  }
  dd {
    padding-left: 0em;
  }
}

#mc-embedded-subscribe {
  cursor: pointer;
}
