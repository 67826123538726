blockquote{
  border: none;
  margin: 0;
  padding-left: 2rem;
}

.file-path-wrapper label{
  margin-left: 105px;
}

.dropzone .dz-preview, .dropzone .dz-preview .dz-image{
  width: 100%;
  height: auto;
  margin: 0;
  border-radius: 0;
}

img{
  max-width: 100%;
}

.dropzone{
  margin-bottom: 1.5em;
}

label[for="project_already_submitted"], label[for="project_description_monospace"] {
  margin-bottom: 1.5em;
}

#attachment-error{
  color: red;
}

.description{
  word-break: break-word;
 }

.project-info p{
  font-size: 12px;
}

.right-panel .title{
  font-size: 1.66rem;
  font-weight: 400;
}

.right-panel .contributor{
  font-size: 1.33rem;
  font-weight: 100;
}

.project-info .contributor{
  font-size: 17px;
  font-weight: 100;
}

.project-info .title{
  font-size: 19px;
}
.project-info .contributor{
  margin-top: 1em;
}
.project-info .home{
  margin: 1em 0;
}

.description p{
  margin: 1rem 0;
}

.material-placeholder{
  margin-top: 1em;
  min-height: 190px;
}

.project-load:hover{
  background: black;
  color: white;
}

.project-img.active{
  max-width: none;
  max-height: none;
}

.right-panel{
  padding-left: 2.5em !important;
  padding-right: 2.5em !important;
  padding-bottom: 1em;
}

.project-img{
  max-width: 100%;
  width: auto;
  height: auto;
}

.photo-credit{
  font-size: 12px;
  position: absolute;
  text-align: right;
  -moz-transform: rotate(-90deg) translate(6px, -100%);
  -webkit-transform: rotate(-90deg) translate(6px, -100%);
  -o-transform: rotate(-90deg) translate(6px, -100%);
  -ms-transform: rotate(-90deg) translate(6px, -100%);
  transform: rotate(-90deg) translate(6px, -100%);
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  max-width: 20vw;
}

.project-collaborators{
  margin-top: 0;
}

.project-name-with-collaborators{
  margin-bottom: 0;
}

.label{
  color: #9e9e9e;
}

.select2-container{
  width: 100%!important;
}

.project-pub{
  margin-left: 33%;
}

.sticky-nav{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  z-index: 4;
}

.sticky-nav-2{
  top: 48px;
  z-index: 3;
}

.sticky-nav-3{
  top: 100px;
  z-index: 2;
}

#scrollspy-nav .active{
  color: black;
  font-weight: 600;
}

.section.scrollspy{
  min-height: 100vh;
  margin-bottom: 3em; /* note: margin here helps resole a bug with scrollSpy getting confused w/ setting window.location.hash (& browser srolling to that element) */
}

.clamp100vh{
  max-height: 100vh;
  overflow: hidden;
}

.project-info{
  overflow-wrap: break-word;
}

.nav-search-row {
  margin-top: 0.5rem;
  margin-bottom: 0;
  max-width: 280px;
}

.nav-search-row input[type=search]{
  height: 3.5rem;
}

#project_search .input-field {
  display: flex;
  align-items: center;
}

.description ol li{
  white-space: normal;
}

.description ol li p{
  display: inline;
}

.description code, .description kbd, .description pre, .description samp{
  word-wrap: normal;
  white-space: pre-wrap;
  background: none;
  font-size: 14px;
}

.all-projects{
  display: flex;
  justify-content: space-between;
}
.all-projects-chip{
  margin-top: 1.5em;
}

.citation.modal{
  word-break: break-word;
}

@media #{$small-and-down} {
  .project-img{
    max-height: none;
  }
  .photo-credit{
    max-width: none;
  }

  .right-panel{
    padding-left: 0.5rem !important;
    padding-right: 1em !important;
  }

  .right-panel .title, .right-panel .contributor{
    display: none;
  }

  .project-info .contributor{
    font-size: 1.33rem;
    font-weight: 100;
  }
  .project-info .title{
    font-size: 1.66rem;
  }

}

@media (min-width: 1281px) {
  .project-info p, .photo-credit{
    font-size: 20px
  }
  .description code, .description kbd, .description pre, .description samp{
    font-size: 22px;
  }
  .right-panel .title{
    font-size: 30px;
  }

  .right-panel .contributor{
    font-size: 28px;
  }
  .project-info .contributor{
    font-size: 22px;
  }

  .project-info .title{
    font-size: 24px;
  }

}

.music-for {
  position: relative;
  top:10px;
  font-size: $h6-fontsize;
  margin: 0;
  padding: 0;
}
