.sidenav {
  width: 200px;
}

@media (min-width: 1281px) {
  .sidenav {
    width: 300px;
  }
  #user-nav {
    font-size: 24px;
  }
}

.collapsible-nav-item:hover{
  background-color: rgba(0,0,0,0.05);
}

.collapsible-nav .waves-effect {
  width: 100%;
}

.collapsible-nav-item.active .indicator{
  transition: transform 250ms ease-in;
  transform: rotate(180deg);
}

#nav {
  position: fixed;
  right: 0;
  top: 0;
  max-height: 100px;
  z-index: 10;
}

#nav a {
  height: 50px;
  width: 50px;
  background-size: 100px;
  background-position: bottom left;
}

#nav a:hover{
  height: 75px;
  width: 75px;
  background-size: 150px;
}

.sidenav a {
  text-decoration: none;
  font-size: 18px;
}

#slide-out {
  overflow-x: hidden;
}

#user-nav, #social-nav{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#user-nav a{
  padding-right: 5px;
  padding-left: 5px;
}
a.username{
  min-width: 50%;
  max-width: 85%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.nav-heading{
  width: 100%;
  text-align: right;
  padding-right: 2em;
}
.nav-item{
  padding: 0 32px;
}
.logout i{
  margin-top: 16px;
}

.nav-indexes-vol{
  padding-left: 1.33em;
}

.nav-index-heading{
  display: flex;
  justify-content: space-around;
}

.nav-back-to-top i {
  color: black;
}
.nav-back-to-top i:hover {
  color: white;
}

.dropdown-content{
  max-height: none;
  overflow-y: none;
}

.dropdown-content li:first-child{
  margin-top: 10px;
}

.autocomplete-content .highlight{
  font-weight: 600;
}

#user-nav a {
  width: 100%;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

#social-nav {
  position: -webkit-sticky;
  position: sticky;
  padding-top: 20px;
  background-color: white;
  text-align: center;
}

#nav-title {
  text-align: center;
  margin-top: 0;
  font-weight: 900;
}

.nav-item {
  font-size: $h5-fontsize;
  padding: 8px 0;
}

@media (max-height: 825px) {
  .sidebar-watermark {
    display: none;
  }
  .nav-item {
    font-size: $h5-fontsize;
    padding: 8px 0;
  }
}

@media (max-width: 1636px) {
  .nav-item {
    font-size: $h5-fontsize;
    padding: 8px 0;
  }
}

@media #{$small-and-down} {
  #social-nav {
    position: unset;
  }
  .sidenav {
    width: 80vw;
  }
  .sidenav li > a.overflow {
    line-height: 2.5em;
    height: auto;
  }
  .sidebar-watermark {
    display: none;
  }
  .nav-item {
    text-align: center;
    font-size: $h4-fontsize;
    padding: 32px 0;
  }
}

@media (max-width: 320px) {
  .sidenav li > a.overflow {
    line-height: 1.25em;
  }
  .sidenav li > a.less-overflow {
    line-height: 2.5em;
  }
}

@media (max-width: 366px) {
  #nav-title  {
    font-size: $h3-fontsize;
  }
}

#social-nav a {
  border-bottom: 2px solid transparent;
}

#social-nav a:hover {
  background-color: white;
  border-bottom: 2px solid black;
}

.social-icon svg {
  height: 30px;
}

.social-icon svg {
  height: 30px;
}

.social-icon svg:hover {
  fill: #000000;
}
